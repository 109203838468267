<template>
    <v-container fluid>
        <page-banner links="Méthodologie"></page-banner>

        <v-card class="black mb-10" tile>
            <v-card-text class="headline white--text">
                <v-row>
                    <v-col cols="1">
                        <v-img src="/ls_logo_white.svg" width="40px" alt=""></v-img>
                    </v-col>
                    <v-col class="align-self-center ml-n8">
                        LearnSpirit, l'outil qui vous aide à rendre vos formations plus efficaces et qualitatives
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card>
            <v-card-text>
                <v-row>
                    <v-col cols="6" class="px-10">
                        <v-row class="headline mt-2 mb-4">Bienvenue dans l’outil LearnSpirit</v-row>
                        <v-row>
                            LearnSpirit se propose de vous aider lors de la conception de formation en structurant celle-ci au travers des différents objectifs.
                        </v-row>

                        <v-row class="headline mt-10 mb-4">Appliquez la pédagogie par objectifs</v-row>
                        <v-row>
                            <p>La pédagogie par objectif est proposée par Ralph Tyler dès le début des années 50. Elle a été proposée dans le domaine de la formation professionnelle avant de s’étendre au domaine de l’éducation scolaire.</p>
                            <p>La pédagogie par objectifs procure la capacité à structurer la formation, et assurer sa progressivité. Donc, la garantie de mettre à disposition des apprenants une formation qu’ils seront capables de suivre. Et qui, s’ils éprouvent des difficultés, permettra de percevoir celles-ci au plus tôt et d’y remédier.</p>
                            <p>Il revient cependant au concepteur d’une formation d’assurer un alignement pédagogique entre l’objectif que l’on cherche à faire atteindre et l’évaluation de son atteinte.</p>
                            <p>La déclaration d’un objectif pédagogique suppose instantanément d’imaginer les critères de son évaluation pour définir les apports du cours.</p>
                        </v-row>
                    </v-col>
                    <v-col cols="6" class="px-10">
                        <v-row class="headline mt-2 mb-4">Comment structurer sa formation avec LearnSpirit</v-row>
                        <v-row>
                            <p>Lorsque vous décidez de concevoir une formation, vous avez en tête un objectif à atteindre, qui peut être très large. En partant de cet objectif et en l’étudiant, on observe rapidement la nécessité d’un découpage.</p>
                            <p>Il est donc nécessaire de définir de multiples étapes dans la formation.</p>
                            <p>Nous connaissons généralement le but recherché par la formation, mais il est indispensable de traduire ce but en objectif pédagogique. Une fois cette étape réalisée, il est nécessaire de déterminer les objectifs généraux de la formation, qui seront autant d’étapes que nécessaires à sa réussite.</p>
                            <p>Ces objectifs se traduisent généralement en termes de compétences.</p>
                        </v-row>
                        <v-row>
                            <v-col md="6" offset="3">
                                <v-img src="/ls_goal.svg" alt="Schéma finalité de LearnSpirit" ></v-img>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-card-text>
        </v-card>

        <v-card class="my-10">
          <v-card-text class="headline" id="explanation">Comment dérouler un projet de formation ?</v-card-text>
        </v-card>

        <v-row>
            <v-col lg="4" md="12">
                <v-tabs v-model="tab" vertical background-color="grey" grow color="white" slider-size="0">
                    <v-tab class="justify-start" active-class="blue darken-1">1. L'analyse du besoin de formation</v-tab>
                    <v-tab class="justify-start" active-class="blue darken-1">2. Le découpage par objectifs</v-tab>
                    <v-tab class="justify-start" active-class="blue darken-1">3. La description des activités</v-tab>
                    <v-tab class="justify-start" active-class="blue darken-1">4. L'accompagnement tutoral</v-tab>
                    <v-tab class="justify-start" active-class="blue darken-1">5. Le déroulé des animations en ligne</v-tab>
                </v-tabs>
            </v-col>
            <v-col>
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-card tile class="px-10">
                            <v-card-text>
                                <v-row class="headline mt-2 mb-8">Le cadrage de la formation</v-row>
                                <v-row>
                                    <p>Toute formation, quelle qu’en soit la taille ou la durée, devrait démarrer par un cadrage strict des besoins établis. Cette étape est indispensable et favorise la compréhension et le suivi de votre projet d’écriture.</p>
                                    <p>L’écriture d’une note de cadrage (cahier des charges du prestataire), ne doit pas être négligée. Et c’est pourquoi LearnSpirit vous propose de démarrer la conception de votre formation par son écriture.</p>
                                    <p>Laissez-vous guider dans sa rédaction et apportez-y les réponses que vous possédez. Ensuite, il vous sera possible d’exporter le résultat de votre cadrage pour en faire bénéficier votre client ou encore pour archiver celle-ci.</p>
                                </v-row>
                                </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card tile class="px-10">
                            <v-card-text>
                                <v-row class="headline mt-2 mb-8">Le découpage par objectifs</v-row>
                                <v-row>

                                    <p>Pour bien comprendre ce qu’on entend par découpage par objectif, prenons un exemple :</p>

                                    <p>Admettons que je travaille pour un organisme de formation. Celui-ci peut avoir pour but de faire évoluer ses
                                        prestations. Passer du tout en présence, à des modalités à distance par exemple.</p>

                                    <p>Son but pourrait alors de modifier sa stratégie en proposant de la formation multimodale. Il est donc nécessaire de
                                        former les formateurs à ces nouvelles modalités. Il devient donc nécessaire d’apprendre à concevoir et animer des
                                        classes virtuelles notamment.</p>

                                    <p>L’objectif de votre formation pourrait donc être traduit de cette manière, par ce que seront capable de faire les
                                        apprenants à l’issue de la formation, «concevoir et animer des classes virtuelles».</p>

                                    <p>Traduit en titre commercial «Concevoir et animer une classe virtuelle» peut aussi devenir le titre de votre
                                        formation.</p>

                                    <p>Une fois cette étape réalisée, il est nécessaire de procéder à un découpage des objectifs de la formation pour que
                                    les apprenants puissent la suivre avec une progression adaptée du début à la fin.</p>

                                    <p>Exemple, si mon objectif principal (but) est :</p>

                                    <p>«Concevoir et animer une classe virtuelle», il est bien trop vague pour savoir à ce stade ce que nous allons y
                                    apporter en termes de cours. Découpons donc celui-ci en autant d’objectifs que nécessaire.</p>

                                    <p>La nécessité de posséder quelques notions en psychologie cognitive est avérée (connaissance du fonctionnement de la
                                    mémoire, des émotions par exemple). Donc un premier objectif pourrait être :</p>

                                    <ul>
                                        <li><p>Être capable d’expliquer la psychologie cognitive en formation pour adultes</p></li>
                                    </ul>

                                    <p>Puis, parce qu’il faut connaître les particularités de la classe virtuelle :</p>

                                    <ul>
                                        <li><p>Être capable de décrire les spécificités de la formation en classe virtuelle</p></li>
                                    </ul>

                                    <p>Et, pour assurer une bonne expérience pour l’apprenant, assurer l’ergonomie de ses supports de cours :</p>

                                    <ul>
                                        <li><p>Être capable de réaliser des supports adaptés à un cours en classe virtuelle</p></li>
                                    </ul>

                                    <p> Et enfin, pour s’assurer que les compétences sont réellement développées, proposer d’animer une classe virtuelle en
                                    autonomie :</p>

                                    <ul>
                                        <li><p>Être capable d’écrire le conducteur d’une classe virtuelle et d’animer celle-ci</p></li>
                                    </ul>


                                    <p>Nous avons décrit les quatre compétences principales proposées par la formation</p>


                                    <p>Ce premier découpage est encore insuffisant pour déterminer avec précision les activités à proposer. Il faut avant
                                    tout rendre les objectifs de la formation «opérationnels». Ils doivent donc induire la capacité à évaluer leur
                                    atteinte de la manière la plus précise possible.</p>

                                    <p>Chaque objectif ci-dessus sera donc découpé en autant d’objectifs opérationnels que nécessaire.</p>

                                    <p>Un objectif opérationnel doit être univoque, n’avoir qu’une seule interprétation possible. Il doit décrire un
                                    comportement observable. Et préciser un contexte de réalisation et déterminer des niveaux d’exigence et de
                                    performance.</p>


                                    <p>Un objectif spécifique représente le plus petit objectif d’un cours, il est donc lié à une activité qui pourra être
                                    évaluée.</p>
                                </v-row>

                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card tile class="px-10">
                            <v-card-text>
                                <v-row class="headline mt-2 mb-8">
                                    La description des activités
                                </v-row>
                                <v-row>

                                    <p>Après avoir décrit l’ensemble de vos objectif et s’être assuré que ceux-ci sont tous évaluables, il reste à définir les modalités et les ressources dont auront besoins les apprenants pour être capable de réussir une évaluation de leurs savoirs, savoir-faire, ou autre savoir-être.</p>
                                    <p>Exemple, pour notre premier objectif opérationnel :</p>
                                    <p>l’apprenant devra être capable d’expliquer le rôle de la psychologie cognitive en formation pour adulte.</p>
                                    <p>Il est alors nécessaire de procéder à un découpage plus fin pour permettre aux apprenants d’assimiler chaque contenu de manière efficace. Cela va nous permettre d’assurer la progressivité dans la formation et de la rendre plus accessible.</p>
                                    <p>Attention à ne pas oublier de se poser la question suivante : qu’est-ce qui devra être évalué pour déterminer l’atteinte de l’objectif opérationnel ?</p>
                                    <p>Pour atteindre l’objectif opérationnel ci-dessus, il est alors possible d’imaginer les réponses suivantes, et se dire que l’apprenant devra être capable de :</p>

                                    <ul>
                                        <li><p>Identifier les éléments de psychologie cognitive utiles en formation pour adultes</p></li>
                                        <li><p>Identifier les apports des sciences de l’éducation en formation pour adultes</p></li>
                                        <li><p>Discriminer les apprenants en formation pour adultes</p></li>
                                        <li><p>Structurer la progressivité d’une formation en ligne</p></li>
                                    </ul>

                                    <p>Chaque objectif spécifique ci-dessus induit alors une activité, qui pourrait être évaluée. Le verbe à l’infinitif correspondant aux attentes en cas d’évaluation, il est désormais facile de déterminer les ressources dont auront besoins les apprenants pour répondre à l’évaluation.</p>

                                    <p>Il est également possible de s’aider d’une référence proposée par Gagné et Briggs (1974), qui favorise l’acquisition de connaissances en suivant neuf étapes qui se succèdent, comme dans l’exemple suivant (être capable de concevoir et d’animer des classes virtuelles) :</p>

                                    <ol>
                                        <li><p>Attirer l'attention: montrer un exemple, ou proposer une classe virtuelle en décrivant son processus et sa capacité à générer des apprentissages efficaces</p></li>
                                        <li><p>Annoncer les objectifs d'apprentissage: annoncer que l'objectif de la formation consistera à concevoir puis d’animer des classes virtuelles.</p></li>
                                        <li><p>Remémorer les connaissances acquises (régulièrement): questionner la connaissance du sujet par rapport à la conception de classes virtuelles (à chaque étape).</p></li>
                                        <li><p>Exposer les nouvelles connaissances: montrer comment concevoir chaque étape.</p></li>
                                        <li><p>Assister l'apprentissage: montrer des éléments médiatiques permettant de comprendre le processus de conception de classes virtuelles (illustration, vidéo, narration, etc.).</p></li>
                                        <li><p>Procéder à l'expérimentation: proposer d’animer une classe virtuelle.</p></li>
                                        <li><p>Donner une rétroaction: observer l'exécution de la tâche et corriger au besoin.</p></li>
                                        <li><p>Évaluer la performance: valider la performance si elle est bien réalisée.</p></li>
                                        <li><p>Favoriser la rétention et le transfert: demander de répéter l'expérience en concevant d’autres classes virtuelles, sur d’autres sujets d’apprentissage.</p></li>
                                    </ol>

                                    <p>LearnSpirit va vous aider à décrire chaque activité et les exporter si besoin sous la forme d’un document appelé “script”.</p>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card tile class="px-10">
                            <v-card-text>
                                <v-row class="headline mt-2 mb-8">
                                    L’accompagnement tutoral
                                </v-row>
                                <v-row>
                                    <p>L’accompagnement des apprenants en formation est non seulement préconisé, mais aussi fortement recommandé. Bien qu’il ne s’agisse pas de supposer qu’un formateur, un tuteur, doit être présent constamment, de simples signes de présence sont nécessaires en formation, surtout si elle est uniquement en ligne.</p>
                                    <p>LearnSpirit ne se propose pas d’imposer un accompagnement, mais il vous permet de l’assurer par anticipation, avec une écriture facilité, et des réponses suggérées selon les objectifs recherchés.</p>
                                    <p>Le concepteur de LearnSpirit a travaillé sur la base des travaux réalisés par Jacques Rodet, et notamment les écrits de deux de ses publications, “L'ingénierie tutorale, 2016” et “Pratiques du tutorat à distance : livret d’interventions, 2020”.</p>
                                    <p>Par ailleurs, Jacques Rodet a validé la proposition d’écriture de l’accompagnement proposé par LearnSpirit.</p>
                                    <p>LearnSpirit vous propose de déterminer avec précision, pour chaque activité, l’accompagnement qui répond aux besoins. Vous choisissez vos objectifs, et des réponses vous sont automatiquement suggérées. Il ne reste alors qu’à définir la manière et le rythme avec lequel proposer l’accompagnement de la formation.</p>
                                    <p>Un document est alors exportable sous la forme d’un storyboard des interventions tutorales.</p>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card tile class="px-10">
                            <v-card-text>
                                <v-row class="headline mt-2 mb-8">
                                    Le déroulé de l’animation en ligne
                                </v-row>
                                <v-row>
                                    <p>Souvent négligé, l’écriture du déroulement de vos animations en ligne est une étape fondamentale à des prestations de qualité.</p>
                                    <p>Ici, commencer par le choix des objectifs pédagogiques pour chaque étape est comme pour la formation une priorité. LearnSpirit doit vous faciliter cet exercice en vous proposant d’écrire chaque étape. Vous pourrez dérouler vos activités pédagogiques en faisant le choix de votre objectif puis des activités à proposer, et ensuite vous pourrez déterminer la posture des apprenants pour chacune d’elles.</p>
                                    <p>Cet exercice vous permettra d’avoir une vision claire du déroulé de votre séance en ligne, et de l’intérêt potentiel de chaque étape pour l’apprenant.</p>
                                    <p>Un document est alors exportable sous la forme d’un storyboard des interventions tutorales.</p>
                                </v-row>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
                <v-row class="mt-3">
                    <v-col cols="2" offset="8">
                        <v-btn class="rounded-pill grey" elevation="0" width="120px" :disabled="tab === 0" @click="changeTab(false)">Précédent</v-btn>
                    </v-col>
                    <v-col cols="2">
                        <v-btn class="rounded-pill pinkred white--text" elevation="0" width="120px" :disabled="tab === 4" @click="changeTab(true)">Suivant</v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PageBanner from "@/components/PageBanner";
export default {
    name: "Home",
    components: { PageBanner },

    data: () => ({
        tab: 0,
    }),

    methods: {
        changeTab: function(next) {
            this.$vuetify.goTo("#explanation");
            this.tab += next ? 1 : -1;
        }
    }

};
</script>

<style scoped>

</style>